import { Button, CircularProgress } from '@mui/material';
import { SxProps, Theme, styled, useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  width: '100%',
  fontWeight: theme.typography.fontWeightMedium,
  '&.Mui-disabled': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
}));

interface IButtonCompProps {
  loading?: boolean;
  children: ReactNode | ReactNode[];
  disabled?: boolean;
  loadingText?: string;
  variant?: 'text' | 'outlined' | 'contained';
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

const ButtonComp = ({
  loading = false,
  children,
  disabled = false,
  loadingText = '',
  variant = 'contained',
  sx = {},
  onClick,
}: IButtonCompProps) => {
  const theme = useTheme();
  return (
    <StyledButton
      onClick={onClick}
      sx={{ ...sx }}
      variant={variant}
      disabled={disabled || loading}
    >
      {loading && (
        <CircularProgress
          size={16}
          sx={{ color: theme.palette.common.white, marginRight: '8px' }}
        />
      )}
      {loading ? loadingText : children}
    </StyledButton>
  );
};

export default ButtonComp;
