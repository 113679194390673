export enum LoadingKeys {
  Login = 'login',
  ResetPassword = 'ResetPassword',
  ConcreteTypes = 'ConcreteTypes',
  ConcreteMix = 'ConcreteMix',
  TechnicalScreen = 'TechnicalScreen',
  ClientSensor = 'ClientSensor',
  Projects = 'Projects',
  ProjectDetails = 'ProjectDetails',
  MarkPourAsDone = 'MarkPourAsDone',
  ExportPourDetails = 'ExportPourDetails',
  CreateOrUpdatePour = 'CreateOrUpdatePour',
  PourGaugeDetails = 'PourGaugeDetails',
  ProjectDashboard = 'ProjectDashboard',
  PourSensors = 'PourSensors',
  UpdateInfo = 'PourSensors',
  DeleteSensor = 'DeleteSensor',
  GetSensorDetails = 'GetSensorDetails',
  GetFinishSensorDetails = 'GetFinishSensorDetails',
  UpdateSensor = 'UpdateSensor',
  GetSensorIdFromQr = 'GetSensorIdFromQr',
  GetHubIdFromQr = 'GetHubIdFromQr',
  UploadHubImage = 'UploadHubImage',

  CheckLifeSign = 'CheckLifeSign',
  AddSensor = 'AddSensor',
  EditSensor = 'EditSensor',
  AddHub = 'AddHub',

  pwaVersion = 'pwaVersion',
  DeleteHub = 'DeleteHub',
  EditHub = 'EditHub'
}
