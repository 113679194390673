import { Box, CircularProgress, styled } from '@mui/material';
export const LoadingOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0,0,0,0.3)',
  zIndex: '99',
});
const LoadingOverlayComp = () => {
  return (
    <LoadingOverlay>
      <CircularProgress style={{ color: '#ffff' }} />
    </LoadingOverlay>
  );
};

export default LoadingOverlayComp;
