import { ROUTES } from '@/constants';
import { useAppStore } from '@/stores';
import { Navigate, Outlet } from 'react-router';

const PrivateRoute = () => {
  const { currentUser } = useAppStore();
  return <Outlet />;
  // return currentUser && currentUser?.access_token ? (
  //   <Outlet />
  // ) : (
  //   <Navigate to={ROUTES.LOGIN} />
  // );
};

export default PrivateRoute;
