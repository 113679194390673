import { FC, ReactNode } from 'react';
import { styled, Theme, SxProps } from '@mui/material/styles';

interface IContainerProps {
  children: ReactNode | ReactNode[];
  sx?: SxProps<Theme>;
}
const StyledContainer = styled('div')(({ theme }) => ({
  padding: `0 ${theme.spacing(4)}`,
}));

const Container: FC<IContainerProps> = ({ children, sx = {} }) => {
  return <StyledContainer sx={sx}>{children}</StyledContainer>;
};

export default Container;
