import { useDateTimeFormat } from '@/hooks';
import { IChartDataItem } from '@/models';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';

export interface AreaChartCompProps {
  data: IChartDataItem[];
  name: 'Strength' | 'Temperature' | 'Resistivity';
  fill: '#7CA1C7' | '#8884d8';
  isMetric: boolean;
  unit: 'PSI' | 'MPa' | '°C' | '°F';
}

const round_10 = (num) =>{
  let ret = parseInt((parseInt(((num) + 10) as any) / 10) as any) * 10;
  return ret;
}

const round_5 = (num) =>{
  let ret = parseInt((parseInt(((num) + 5) as any) / 5) as any) * 5;
  return ret;
}


const AreaChartComp = ({ data, name, fill, unit }: AreaChartCompProps) => {
  const { formatDate, dateFormat } = useDateTimeFormat();
  const [lastValue, setLastValue] = useState(null);
  const [minValue, setMinValue] = useState(null);
  const [ticksArr, setTicksArr] = useState(null);
  const [wait_for_calc, setFinishCalc] = useState(false);
  // var lastValue = 60;// round_5(data.map(x => parseFloat(x.value)).sort(function(a, b){return a-b}).at(-1));
  // var minValue = 0;// round_5(data.map(x => parseFloat(x.value)).sort(function(a, b){return a-b}).at(0)) -5;
  // var ticksArr = [0,15,60]//[minValue,round_5(minValue+((lastValue-minValue)/4)),round_5((minValue+((lastValue-minValue)/4)*2)), lastValue];
  useEffect(() => {
    if(data){
      let tmpLastValue = round_5(Math.max(...data.map(x => parseFloat(x.value))));
      setLastValue(tmpLastValue);
      //setLastValue(round_5(data.map(x => parseFloat(x.value)).sort(function(a, b){return a-b}).at(-1)));
     console.log(lastValue)
    //  setMinValue(round_5(data.map(x => parseFloat(x.value)).sort(function(a, b){return a-b}).at(0)) -5);
     let tmpMinValue = round_5(Math.min(...data.map(x => parseFloat(x.value))))-5
     setMinValue(tmpMinValue)
     console.log(minValue)
     if((tmpLastValue-tmpMinValue) <= 5){
      let arr = []
      for(let i=tmpMinValue;i<tmpLastValue+1;i++){
        arr.push(i)
      }
      setTicksArr(arr)//
      setTimeout(() => {
        setFinishCalc(true);
      }, 0);
     }else{
      let tick_gap = round_5((tmpLastValue-tmpMinValue)/5);
      if((tmpLastValue-tmpMinValue)/tick_gap < 4){
        setTicksArr([tmpMinValue,tmpMinValue+tick_gap,tmpMinValue+2*tick_gap,tmpMinValue+3*tick_gap]);
        console.log(ticksArr)
        setTimeout(() => {
          setFinishCalc(true);
        }, 0);
      }else{
        setTicksArr([tmpMinValue,tmpMinValue+tick_gap,tmpMinValue+2*tick_gap,tmpMinValue+3*tick_gap,tmpMinValue+4*tick_gap]);
        console.log(ticksArr)
        setTimeout(() => {
          setFinishCalc(true);
        }, 0);
      }
      
     }
     
    }
     
  }, [data]);

  // const lastValue = parseInt(((parseInt((data.map(x => parseFloat(x.value))).sort(function(a, b){return a-b}).at(-1) as any) + 10) / 10) as any) * 10
  // Data Processing: Convert date strings to Date objects before using them in the charts
  const processedData = data?.map((item) => ({
    ...item,
    date: new Date(item.date),
  })) || []; // <-- Added check for undefined data

  return (
    <Box>
      <Typography
        variant='h6'
        color='common.black'
        sx={{ mb: '10px', textAlign: 'center' }}
      >
        {name}
        {wait_for_calc && data &&
          data?.length > 0 &&
          ` ${data?.[data?.length - 1]?.value} ${unit}`}
      </Typography>
      {wait_for_calc &&
      <ResponsiveContainer width='100%' height={200}>
        <AreaChart
          margin={{ top: 0, right: 30, left: 30, bottom: 0 }}
          width={500}
          height={200}
          data={processedData}
        >
          <defs>
            <linearGradient id={`${name}color`} x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor={fill} stopOpacity={0.8} />
              <stop offset='95%' stopColor={fill} stopOpacity={0.7} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray='2 2' />
          <XAxis 
          dataKey='date'
          scale="time" 
          tick={false}>
            <Label
              value={` ${formatDate(data?.[0]?.date, 'time')}
               to ${formatDate(data?.[data?.length - 1]?.date, 'time')}`}
              position='insideBottom'
              fill={fill}
            />
          </XAxis>
          <YAxis
            domain={[minValue, lastValue]}
            dataKey='value'
            ticks={ticksArr} 
            interval={0} 
            tick={({ payload, x, y, stroke, index }) => (
              <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
                  {payload.value}
                </text>
              </g>
            )}
            tickFormatter={value => `${value?.toFixed(0)}`}
            allowDataOverflow = {false}
            range={[0.1,0.2,0.7]}
            >
            <Label
              value={name}
              position={{ x: 0, y: 50 }}
              angle={-90}
              textAnchor='middle'
              fill={fill}
            />
          </YAxis>

          <Tooltip
            offset={100}
            labelFormatter={value => {
              return `Time : ${formatDate(value, 'time')}`; //${formatDate(value, 'time')}
            }}
          />
          <Area
            name={name}
            type='monotone'
            dataKey='value'
            stroke={fill}
            fill={`url(#${name}color)`}
          />
        </AreaChart>
      </ResponsiveContainer>}
    </Box>
  );
};

export default AreaChartComp;
