import {
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material';
import FlexWrapper from '../flex-wrapper';
import { Close, CloseCircle, Logout, Message, Settings } from '../icons';
import { t } from 'i18next';
import {
  AvatarWrapper,
  DrawerInnerWrapper,
  StyledDrawer,
  StyledListItem,
} from './header.styled';
import { useMemo } from 'react';
import { generateInitials } from '@/utils';
import { useAppStore } from '@/stores';
import { useNavigate } from 'react-router';
import { ROUTES } from '@/constants';
import { StyledCloseIcon } from '../search-input';
import { version } from '../../version';

interface SideMenuProps {
  isOpen: boolean;
  onClose: () => void;
  userName: string;
  userEmail: string;
}

const SideMenu = ({ isOpen, onClose, userName, userEmail }: SideMenuProps) => {
  const { logout } = useAppStore();
  const navigate = useNavigate();
  const listItems = useMemo(
    () => [
      {
        name: t('settings'),
        onClick: () => {
          onClose();
          navigate(ROUTES.SETTINGS);
        },
        icon: <Settings />,
      },
      {
        name: t('contactUs'),
        onClick: () => {
          window.location.href = 'https://greenvibe.io/contact/';
        },
        icon: <Message />,
      },
      {
        name: t('logout'),
        onClick: () => {
          logout(() => {
            onClose();
            navigate(ROUTES.LOGIN);
          });
        },
        icon: <Logout />,
      },
      {
        name: `${t('version')}: ${version}` ,
         
      },
    ],
    []
  );

  const theme = useTheme();
  return (
    <StyledDrawer anchor='left' open={isOpen} onClose={onClose}>
      <DrawerInnerWrapper>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            '&:hover': {
              '& path': {
                fill: theme.palette.error.light,
              },
            },
            // transform: 'translate(-50%,50%)',
          }}
        >
          <CloseCircle />
        </IconButton>

        <FlexWrapper alignItems='center' justifyContent='center'>
          <Box sx={{ textAlign: 'center' }}>
            <AvatarWrapper>
              <FlexWrapper
                justifyContent='center'
                alignItems='center'
                sx={{ height: '100%' }}
              >
                {generateInitials(userName)}
              </FlexWrapper>
              {/* <StyledAvatar src='assets/avatar.png' alt='avatar' /> */}
            </AvatarWrapper>
            <Typography sx={{ marginTop: '8px' }} variant='h6'>
              {userName}
            </Typography>
            <Typography
              variant='body2'
              sx={{ color: theme.palette.neutral.light }}
            >
              {userEmail}
            </Typography>
          </Box>
        </FlexWrapper>
        <Box sx={{ flex: 1, paddingTop: '84px' }}>
          <FlexWrapper direction='column' sx={{ height: '100%' }}>
            {listItems.map((item, index) => (
              <StyledListItem
                onClick={item.onClick}
                sx={{
                  marginTop:
                    index >= listItems.length - 2 ? 'auto' : 'initial',
                }}
                disablePadding
                key={item.name}
              >
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </StyledListItem>
            ))}
          </FlexWrapper>
        </Box>
      </DrawerInnerWrapper>
    </StyledDrawer>
  );
};

export default SideMenu;
