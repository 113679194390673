import { Drawer, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderWrapper = styled('nav')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: '15px 0 10px',
}));

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '278px',
    backgroundColor: theme.palette.primary.main,
  },
}));

export const DrawerInnerWrapper = styled('div')({
  padding: '58px 28px 75px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const AvatarWrapper = styled('div')(({ theme }) => ({
  borderRadius: '50%',
  overflow: 'hidden',
  margin: '0 auto',
  backgroundColor: theme.palette.neutral.light,
  width: '80px',
  height: '80px',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(30),
  color: theme.palette.primary.darker,
}));

export const StyledAvatar = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'cover',
});

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  '& .MuiListItemIcon-root': {
    minWidth: '24px',
    marginRight: '8px',
  },
  '& .MuiTypography-root': {
    color: theme.palette.common.white,
  },
}));
