import { string, number } from 'yup';
import { t } from 'i18next';

export const requiredString = string().required(t('thisFieldIsMandatory'));
export const requiredNumber = number()
  .typeError(t('thisFieldAcceptsNumbersOnly'))
  .required(t('thisFieldIsMandatory'));
export const email = requiredString.email(t('pleaseEnterValidEmail'));
export const numberWithMinValueOfZero = number()
  .typeError(t('thisFieldAcceptsNumbersOnly'))
  .min(0, t('thisFieldNotAcceptValuesLessThanZero'));
