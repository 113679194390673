import { Link, To } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: 'underline',
  fontSize: `${theme.typography.body2.fontSize}`,
}));

export interface LinkCompProps {
  to: To;
  children: ReactNode | ReactNode[];
  onClick?: () => void;
}
const LinkComp = ({ to, children, onClick = () => {} }: LinkCompProps) => {
  return (
    <StyledLink to={to} onClick={onClick}>
      {children}
    </StyledLink>
  );
};

export default LinkComp;
