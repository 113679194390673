import { useEffect } from 'react';
import NProgress from 'nprogress';
import { styled } from '@mui/material/styles';

const LoaderWrapper = styled('div')({
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const AnimatedPulse = styled('div')(({ theme }) => ({
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  position: 'relative',
  backgroundColor: theme.palette.neutral.main,
  '@keyframes animateXl': {
    '0%': {
      transform: 'scale(1.05)',
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.75)',
      opacity: 0,
    },
  },
  '&:after , &:before ': {
    content: '""',
    position: 'absolute',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.neutral.light}`,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    animation: `animateXl 2.5s linear infinite`,
    backfaceVisibility: 'hidden',
  },
}));

const SuspenseLoader = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);
  return (
    <LoaderWrapper>
      <AnimatedPulse />
    </LoaderWrapper>
  );
};

export default SuspenseLoader;
