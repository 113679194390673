import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  IconButton,
} from '@mui/material';
import { t } from 'i18next';
import ButtonComp from '../button';
import { CloseCircle } from '../icons';
import { ReactNode } from 'react';

interface IPopConfirmProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  subTitle?: string;
  children?: ReactNode | ReactNode[];
  isConfirmLoading?: boolean;
  confirmText?: string | ReactNode;
  loadingConfirmText?: string;
  cancelText?: string | ReactNode;
  withCancelBtn?: boolean;
  confirmBtnType?: 'primary' | 'success';
}
const PopConfirm = ({
  onConfirm,
  onCancel,
  isOpen,
  title,
  subTitle,
  children,
  isConfirmLoading = false,
  confirmText = t('confirm'),
  cancelText = t('discard'),
  withCancelBtn = true,
  confirmBtnType = 'success',
  loadingConfirmText,
}: IPopConfirmProps) => {
  const theme = useTheme();
  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '300px',
          minWidth: '300px',
        },
        '& .MuiBackdrop-root': {
          background: 'rgba(0,0,0,0.8)',
        },
      }}
      open={isOpen}
      onClose={onCancel}
      aria-labelledby='confirm-dialog-title'
      aria-describedby='confirm-dialog-description'
    >
      <DialogTitle
        sx={{
          color: 'black.darker',
          fontWeight: theme.typography.fontWeightBold,
          fontSize: theme.typography.h6.fontSize,
          textAlign: 'center',
          pt: '30px',
        }}
        id='confirm-dialog-title'
      >
        {title}
      </DialogTitle>
      <IconButton
        onClick={onCancel}
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          '&:hover': {
            '& path': {
              fill: theme.palette.error.light,
            },
          },
          // transform: 'translate(-50%,50%)',
        }}
      >
        <CloseCircle />
      </IconButton>
      <DialogContent sx={{ pt: 0 }}>
        <DialogContentText
          id='alert-dialog-description'
          sx={{
            color: 'grey.600',
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.typography.pxToRem(14),
            textAlign: 'center',
          }}
        >
          {subTitle}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'space-between', padding: '0 24px 24px' }}
      >
        {withCancelBtn && (
          <ButtonComp
            sx={{
              padding: '9.5px 30px',
              backgroundColor: 'transparent',
              color: '#2B2B2B',
              borderRadius: '8px',
              border: '1px solid #acacac',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: theme.palette.text.disabled,
                color: theme.palette.common.white,
              },
            }}
            onClick={onCancel}
          >
            {cancelText}
          </ButtonComp>
        )}

        <ButtonComp
          loadingText={loadingConfirmText}
          loading={isConfirmLoading}
          sx={
            confirmBtnType === 'success'
              ? {
                  height: '45px',
                  padding: '9.5px 30px',
                  borderRadius: '8px',
                  backgroundColor: theme.palette.success.main,
                  '&:hover': {
                    backgroundColor: `${theme.palette.success.darker} !important`,
                  },
                  '&.Mui-disabled': {
                    opacity: 0.3,
                    backgroundColor: `${theme.palette.success.main} !important`,
                  },
                }
              : {}
          }
          onClick={onConfirm}
        >
          {confirmText}
        </ButtonComp>
      </DialogActions>
    </Dialog>
  );
};
export default PopConfirm;
