import moment from 'moment';
import { IPdfDetails, IPourExportDetailsItem } from '@/models';
import { printDivId } from '@/constants';
import { useMemo } from 'react';
import { useCubeOrCylinder, useDateTimeFormat, useUnitType } from '@/hooks';
import { cubeToCelinderandPsi } from '@/utils';

interface PourPdfPageProps {
  jsonData: IPourExportDetailsItem[];
  pdfDetails: IPdfDetails & { projectName: string; userName: string };
}
const PourPdfPage = ({
  jsonData,
  pdfDetails: {
    projectName,
    userName,
    pourDataName,
    concreteType,
    pourStartTime,
  },
}: PourPdfPageProps) => {
  const { isMetric } = useUnitType();
  const { isCylinder } = useCubeOrCylinder();
  const unit = isMetric? 'MPa': 'PSI';
  const styles = {
    wholePage: {
      display: 'none',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '20px 40px',
    },
    topTableTd: {
      border: 0,
    },
    section: {
      marginBottom: '20px',
    },
    h1: {
      textAlign: 'center',
      marginBottom: '20px',
      fontWeight: 'normal',
      fontSize: '20px',
    },
    detailsTableTd: {
      padding: '10px',
      textAlign: 'left',
    },
    detailsTableTh: {
      backgroundColor: '#f2f2f2',
      textAlign: 'left',
      padding: '10px',
    },
    columnLayout: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '3rem 0 5rem 0',
      gap: '2rem',
    },

    column: {
      display: 'flex',
      flexDirection: 'column',
    },

    spacer2: {
      height: '2rem',
    },

    fullWidth: {
      width: '100%',
    },

    marginb0: {
      marginBottom: 0,
    },
  } as const;
  const { formatDate } = useDateTimeFormat();

  const totalAverageStrength = useMemo(() => {
    if (jsonData && Array.isArray(jsonData) && jsonData.length > 0) {
      let total = 0;
      jsonData.forEach(item => {
        if (item.strength_derivative_1) {
          total += parseFloat(item.strength_derivative_1 as any);
        }
      });
      return cubeToCelinderandPsi((total / jsonData.length),isMetric,isCylinder)?.toFixed(2);
    } else {
      return 'N/A';
    }
  }, [jsonData]);

  return (
    <div id={printDivId} style={styles.wholePage}>
      <div style={styles.section}>
        <img
          style={{ width: '25%', height: '25%' }}
          src={process.env.PUBLIC_URL + '/full_logo_new.png'}
          alt='Greenvibe'
        />
      </div>
      <div style={styles.section}>
        <h1 style={styles.h1}>
          Inspection Report:{'   '}
          {formatDate(new Date(), 'time')}
        </h1>
      </div>

      <div style={styles.section}>
        <table className='top-details-table' style={{ border: 'none' }}>
          <tbody>
            <tr>
              <td style={styles.topTableTd}>Project name:</td>
              <td style={styles.topTableTd}>{projectName}</td>
            </tr>
            <tr>
              <td style={styles.topTableTd}>Export by:</td>
              <td style={styles.topTableTd}>{userName}</td>
            </tr>
            <tr>
              <td style={styles.topTableTd}>Element tested:</td>
              <td style={styles.topTableTd}>{pourDataName}</td>
            </tr>
            <tr>
              <td style={styles.topTableTd}>Concrete type:</td>
              <td style={styles.topTableTd}>{concreteType}</td>
            </tr>
            <tr>
              <td style={styles.topTableTd}>Cast start time:</td>
              <td style={styles.topTableTd}>
                {formatDate(pourStartTime, 'time')}
              </td>
            </tr>
            <tr>
              <td style={styles.topTableTd}>Days from casting:</td>
              <td style={styles.topTableTd}>
                {moment(pourStartTime).isValid()
                  ? moment().diff(moment(pourStartTime), 'day')
                  : ''}
              </td>
            </tr>
          </tbody>
        </table>

        <table
          style={{
            borderCollapse: 'collapse',
            width: '100%',
            marginTop: '20px',
          }}
        >
          <thead>
            <tr>
              <th style={styles.detailsTableTh}>Sensor name</th>
              <th style={styles.detailsTableTh}>Sensor location</th>
              <th style={styles.detailsTableTh}>Strength [{unit}]</th>
              <th style={styles.detailsTableTh}>Last Indication</th>
            </tr>
          </thead>
          <tbody>
            {jsonData.map(item => (
              <tr>
                <td style={styles.detailsTableTd}>{item.display_name}</td>
                <td style={styles.detailsTableTd}>{item.location}</td>
                <td style={styles.detailsTableTd}>
                  {cubeToCelinderandPsi((parseFloat(item.strength_derivative_1 as any)),isMetric,isCylinder)?.toFixed(2)}
                  {/* parseFloat(item.strength_derivative_1 as any).toFixed(2)} */}
                </td>
                <td style={styles.detailsTableTd}>
                  {formatDate(item.sensor_ts_utc, 'time')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ position: 'relative', left: '45%', marginTop: '10px' }}>
          <b>Average strength: {totalAverageStrength} [{unit}] </b>
        </div>
      </div>
    </div>
  );
};

export default PourPdfPage;
