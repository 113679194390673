import { Box, Button, TextField, styled, useTheme } from '@mui/material';
import { ChangeEventHandler, useRef } from 'react';
import { Camera } from '../icons';
import { t } from 'i18next';

interface UploadInputProps {
  label?: string;
  value?: any;
  helperText?: string;
  error?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const StyledLabel = styled('label')(({ theme }) => ({
  width: 'auto',
  height: '32px',
  overflow: 'hidden',
  position: 'absolute',
  top: '0',
  right: '0',
  zIndex: '999',
  transform: 'translateY(50%)',
  '& input': {
    opacity: 0,
  },
  '& span': {
    color: theme.palette.secondary.main,
    ml: '6px',
    textTransform: 'capitalize',
  },
}));

const UploadInput = ({
  label = 'Attached Photo',
  value,
  helperText,
  error,
  onChange,
}: UploadInputProps) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  return (
    <Box
      position='relative'
      sx={{
        mb: '20px',
      }}
    >
      <TextField
        sx={{
          pointerEvents: 'none',
        }}
        fullWidth
        hidden
        id='photo'
        name='photo'
        variant='standard'
        label={label}
        value={value?.name as any}
        InputLabelProps={{ shrink: true }}
        error={error}
        helperText={helperText}
      />
      <StyledLabel
        htmlFor='upload-photo'
        onKeyDown={e => e.keyCode === 32 && uploadInputRef.current?.click()}
      >
        <Button onClick={() => uploadInputRef.current?.click()}>
          <Camera />
          <Box
            component='span'
            sx={{ color: theme.palette.secondary.main, ml: '6px' }}
          >
            {value ? t('tryAgain') : t('attachPhoto')}
          </Box>
        </Button>
        <input
          id='upload-photo'
          ref={uploadInputRef}
          type='file'
          accept='image/*'
          hidden
          onChange={e => {
            const selectedFile: any = e.target?.files?.[0];
            if (selectedFile) {
              onChange(selectedFile);
            }
          }}
        />
      </StyledLabel>
    </Box>
  );
};

export default UploadInput;
