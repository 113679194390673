import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import {
  Box,
  CardContent,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  useTheme,
} from '@mui/material';
import {
  ArrowRight,
  Concrete,
  Edit,
  Location,
  MenuIcon,
  Sensor,
  BatteryPercentage,
} from '../icons';
import {
  StyledCardHeader,
  StyledListItem,
  StyledProjectCard,
} from '../project-card/project-card.styled';
import { t } from 'i18next';
import { ISensorItem, ISensorStatus } from '@/models';
import { useProjectStore } from '@/stores';
import { routesDetails } from '@/constants';
import { useNavigate } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import { StyledMenuItem } from '../pour-card';
import { useUnitType,useCubeOrCylinder } from '@/hooks';
import { mpaToPsi,cubeToCelinder } from '@/utils';
import InfoIcon from '@mui/icons-material/Info';

interface IProjectCardProps {
  isDisabled: boolean;
  sensorData: ISensorItem;
  sensorTechnicalStatus?: ISensorStatus; // optional
  pourId: string;
  pourName: string;
  handleDeleteSensorClick: (sensorId: string) => void;
  projectId: string;
  errors?: any[]; // Add errors as a prop
}

const SensorCard = ({
  sensorData = {} as ISensorItem,
  pourId,
  isDisabled,
  projectId,
  pourName,
  handleDeleteSensorClick,
  errors = [], // Default to an empty array if no errors are passed
}: IProjectCardProps) => {
  const theme = useTheme();
  const { isMetric } = useUnitType();
  const { isCylinder } = useCubeOrCylinder();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  // Use useLocation to access the passed state
  const errorLocation = useLocation();
  const passedErrors = errorLocation.state?.errors || []; // Access errors from location.state

  // Merge or prioritize errors (you can decide whether to use passedErrors or default props)
  const allErrors = passedErrors.length > 0 ? passedErrors : errors;

  const { name, display_name, location, sensor_id, last_strength } = sensorData;

  const getTechnicalScreen = useProjectStore((state) => state.getTechnicalScreen);
  const technicalScreenData = useProjectStore((state) => state.technicalScreenData);

  // Fetch technical screen data
  useEffect(() => {
    getTechnicalScreen(Number(projectId));
  }, [getTechnicalScreen, projectId]);

  // Find the specific sensor data
  const sensorTechnicalData = technicalScreenData?.[0]?.project_sensors.find(s => s.sensor_id === sensor_id);
  const batteryDeviceNo = sensorTechnicalData?.device_number;

  function calculateBatteryPercentage(sensorBattery, min = 2600, max = 3650) {
    // If sensorBattery is less than min, return 0
    if (sensorBattery < min) {
        return 0; // Or return -1 or "Damaged" to flag an error
    }
    // If sensorBattery is more than max, return 100
    if (sensorBattery > max) {
        return 100; // Indicates full battery
    }
    // Calculate percentage between min and max
    const percentage = ((sensorBattery - min) / (max - min)) * 100;
    // Round the result to the nearest integer and cap it between 0 and 100
    return Math.round(Math.max(0, Math.min(100, percentage)));
  }

  const batteryValue = batteryDeviceNo !== null
    ? sensorTechnicalData?.sensor_battery
    : calculateBatteryPercentage(sensorTechnicalData?.sensor_battery);

  //console.log('sensorId:', sensor_id, 'betteryValue:', batteryValue, 'sensor_battert:', sensorTechnicalData?.sensor_battery);

  // Color determination functions
  const getBatteryColorPercentage = (percentage) => {
    if (percentage === null || percentage === undefined) {
      return '#CECECE'; // Default color if no battery value
    }
    if (percentage < 40) {
      return '#bc544b'; // Red color for battery < 40%
    } else if (percentage < 80) {
      return '#cda984'; // Yellow color for 40% <= battery < 80%
    } else {
      return '#009999'; // Green color for battery >= 80%
    }
  };

  // Determine battery color
  const batteryColor = batteryValue === null ? '#CECECE' : getBatteryColorPercentage(batteryValue);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
    e?.stopPropagation?.();
    setAnchorEl(null);
  };

  // Filter errors related to the current sensor
  const sensorErrors = allErrors.filter(error =>
    error.includes(`id: ${sensor_id}`)
  );
  

  return (
    <>
      <Box sx={{ overflow: 'auto', flex: 1 }}>
        <StyledProjectCard
          onClick={() =>
            navigate(
              routesDetails.Sensor(
                projectId,
                pourId,
                pourName,
                sensor_id,
                name ?? display_name,
                isDisabled
              )
            )
          }
        >
          <StyledCardHeader
            action={
              <IconButton aria-label='view Project'>
                <ArrowRight />
              </IconButton>
            }
            title={
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <span>{name ?? display_name}</span>
                <BatteryPercentage 
                  value={batteryValue} 
                  fillColor={batteryColor} 
                  batterySymbol="%"
                  strokeColor="white"
                />
              </div>
            }
          />

          <CardContent sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: '0',
                right: '0',
                zIndex: '99',
              }}
            >
              <IconButton
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                aria-label='options'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <StyledMenuItem
                  disabled={isDisabled}
                  onClick={e => {
                    e.stopPropagation();
                    handleDeleteSensorClick(sensor_id);
                    handleClose(e);
                  }}
                >
                  <Box
                    component='span'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      '& svg': {
                        width: '15px',
                        height: '15px',
                        fill: 'red',
                      },
                    }}
                  >
                    <CloseIcon />
                  </Box>
                  {t('delete')}
                </StyledMenuItem>
                <StyledMenuItem
                  disabled={isDisabled}
                  onClick={e => {
                    e.stopPropagation();
                    navigate(routesDetails.editSensor(sensor_id,projectId, pourId));
                    handleClose(e);
                  }}
                >
                  <span>
                    <Edit />
                  </span>
                  {t('editSensor')}
                </StyledMenuItem>
              </Menu>
            </Box>
            <List dense disablePadding>
              <StyledListItem>
                <ListItemIcon>
                  <Location />
                </ListItemIcon>
                <ListItemText>
                  <span>{`${t('location')} :`}</span>
                  {location}
                </ListItemText>
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon
                  sx={{
                    '& path': {
                      fill: theme.palette.primary.main,
                      fillOpacity: 1,
                    },
                  }}
                >
                  <Sensor />
                </ListItemIcon>
                <ListItemText>
                  <span>{`${t('sensorId')} :`}</span>
                  {sensor_id}
                </ListItemText>
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon
                  sx={{
                    '& path': {
                      fill: theme.palette.primary.main,
                      fillOpacity: 1,
                    },
                  }}
                >
                  <Concrete />
                </ListItemIcon>
                <ListItemText>
                  <span>{`${t('lastStrength')} :`}</span>
                  {last_strength?
                  isMetric?
                       isCylinder?
                        `${cubeToCelinder(last_strength)?.toFixed(2)} ${t('mpa')}` 
                        : `${last_strength?.toFixed(2)} ${t('mpa')}` 
                  :isCylinder?
                       `${mpaToPsi(cubeToCelinder(last_strength))?.toFixed(2)} ${t('psi')}` 
                  :`${mpaToPsi(last_strength)?.toFixed(2)} ${t('psi')}`
                  : `${t('NA')}`}
                </ListItemText>
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon>
                  {sensorErrors.length > 0 && <InfoIcon sx={{ color: 'red' }} />}
                </ListItemIcon>
                <ListItemText>
                  {sensorErrors.length > 0 && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        overflow: 'visible',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        maxWidth: '100%',
                      }}
                    >
                      {sensorErrors.map((error, index) => {
                        const match = error.match(/id:\s*\d+\s*(.*)/);
                        const messagePart = match ? match[1] : error;
                                      
                        return (
                          <span key={index}>
                            {messagePart || error}
                          </span>
                        );
                      })}
                    </Box>
                  )}
                </ListItemText>
              </StyledListItem>
            </List>


            
          </CardContent>
        </StyledProjectCard>
      </Box>
    </>
  );
};

export default SensorCard;
