
var api_prefix = process.env.NODE_ENV !== 'development' ? '/api' : '';


export const API_ENDPOINTS = {
  
  auth: {
    login: `${api_prefix}/auth/login`,
    resetPassword: `${api_prefix}/auth/ask-for-password-reset`,
    updateInfo: useId => `${api_prefix}/users/myself/${useId}`,
  },
  project: {
    pwaVersion: `${api_prefix}/pwaversion`,
    concreteTypes: `${api_prefix}/server_info`,
    ClientSensor: `${api_prefix}/physical-components/getclientsensors`,
    projects: `${api_prefix}/projects`,
    projectspwa: `${api_prefix}/greenvibe/project/pwaprojects`,
    projectGraph: projectId => `${api_prefix}/greenvibe/project/${projectId}/graph`,
    projectDetails: projectId => `${api_prefix}/greenvibe/project/${projectId}/dashboard`,
    markPourAsDone: (projectId: string, pourId: number) =>
      `${api_prefix}/materials/${projectId}/pour/${pourId}/finish`,
    pourDetails: pourId => `${api_prefix}/materials/pourdetails/${pourId}`,
    createPour: `${api_prefix}/materials/pour`,
    updatePour: pourId => `${api_prefix}/materials/pour/${pourId}`,
    pourGaugeDetails: (pourId: string) => `${api_prefix}/materials/pourgagedetails/${pourId}`,
    projectPours: (projectId: string) =>
      `${api_prefix}/greenvibe/project/${projectId}/pwagagesdashboard`,
    finishedPours: (projectId: string) => `${api_prefix}/materials/${projectId}/finishpours`,
    pourSensors: (pourId: string, isFinished: boolean) =>
      `${api_prefix}/physical-components/${
        isFinished ? 'getfinishpoursensors' : 'getpoursensors'
      }/${pourId}`,
    deleteSensor: (pourId: string, sensorId: string) =>
      `${api_prefix}/physical-components/${pourId}/sensor/${sensorId}`,
    geteditsensordetails: (pourId: number,sensorId: string) => `${api_prefix}/physical-components/editsensordetails/${pourId}/${sensorId}`,
    sensorDetails: (pourId: number,sensorId: string) => `${api_prefix}/greenvibe/sensor-detail/${pourId}/${sensorId}`,
    finshSensorDetails: (sensorId: string ,pourId: number) => `${api_prefix}/greenvibe/finish-sensor-detail/${pourId}/${sensorId}`,
    sensorIdFromQr: `${api_prefix}/materials/getsensoridfromqr`,
    hubIdFromQr: `${api_prefix}/materials/gethubidfromqr`,
    hubImageUpload: hubId => `${api_prefix}/physical-components/uploadimage/${hubId}`,

    checkLifeSign: (sensorOrHubId: string, component: 'hub' | 'sensor') =>
      `${api_prefix}/physical-components/lifesign/${component}/${sensorOrHubId}`,
    addSensor: (sensorId: string) =>
      `${api_prefix}/physical-components/sensor/${sensorId}/assign`,
    addHub: (hubId: string) => `${api_prefix}/physical-components/hub/${hubId}/assign`,
    updateSensor: (sensorId: string) => `${api_prefix}/materials/editsensor/${sensorId}`,
    technicalScreenData: (projectId: number) => `${api_prefix}/physical-components/technicalscreen/${projectId}`,
    deleteHub: (hubId: string) => `${api_prefix}/physical-components/hub/${hubId}/delete`,
    editHub: (hubId: string) => `${api_prefix}/materials/editHub/${hubId}`,
    getConcreteMix: (projectId: number) => `${api_prefix}/greenvibe/concretemix/${projectId}`,
  },
};
