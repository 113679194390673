import { Card, CardHeader, ListItem, styled } from '@mui/material';

export const StyledProjectCard = styled(Card)({
  margin: '20px 0',
  boxShadow: '0px 4px 8px rgba(0, 153, 153, 0.1)',
  borderRadius: '8px',
  // maxHeight: '571px',
  overflow: 'auto',
  '& .MuiCardContent-root.MuiCardContent-root': {
    padding: '16px',
  },
});

export const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.primary.darker,
  padding: '9.5px 10px 9.5px 16px',

  '& .MuiTypography-h5': {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.white,
  },
  '& .MuiList-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: '2px 0',
  color: theme.palette.black.darker,
  '& .MuiTypography-root ': {
    color: theme.palette.black.darker,
    fontWeight: theme.typography.fontWeightBold,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '& span': {
      fontWeight: theme.typography.fontWeightLight,
      display: 'inline-block',
      marginRight: '5px',
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: '24px',
    marginRight: '8px',
  },
}));
