import { ReactNode, useState } from 'react';
import { useAppStore } from '@/stores';
import { Typography, IconButton, Box } from '@mui/material';
import { ArrowLeft, Container, FlexWrapper, MenuToggler } from '@/components';
import SearchInput from '../search-input';
import SideMenu from './side-menu';
import { HeaderWrapper } from './header.styled';
import { useNavigate } from 'react-router';

export interface IHeaderProps {
  withMenuToggler?: boolean;
  withSearch?: boolean;
  title?: string;
  children?: ReactNode | ReactNode[];
  handleSearch?: (searchVal: string) => void;
  handleArrowClick?: () => void;
  searchPlaceholderText?: string;
}

const Header = ({
  withMenuToggler = false,
  withSearch = false,
  title,
  children,
  handleSearch,
  handleArrowClick,
  searchPlaceholderText,
}: IHeaderProps) => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { currentUser } = useAppStore();

  return (
    <HeaderWrapper>
      <Container>
        <FlexWrapper alignItems='center' justifyContent='space-between'>
          <IconButton
            color='primary'
            aria-label={withMenuToggler ? 'toggleMenu' : 'goBack'}
            component='span'
            onClick={() => {
              if (withMenuToggler) {
                setIsDrawerOpen(true);
              } else {
                if (handleArrowClick) {
                  handleArrowClick?.();
                } else {
                  navigate(-1);
                }
              }
            }}
          >
            {withMenuToggler ? <MenuToggler /> : <ArrowLeft />}
          </IconButton>
          <Typography
            sx={{ flex: 1, textAlign: 'center', textTransform: 'capitalize' }}
            component='h1'
            variant='h6'
          >
            <Box
              component='span'
              sx={{
                transform: `translateX(-${withMenuToggler ? 20 : 13}px)`,
                display: 'inline-block',
              }}
            >
              {title}
            </Box>
          </Typography>
        </FlexWrapper>
        {withSearch && (
          <Box sx={{ my: '15px' }}>
            <SearchInput
              handleSearch={handleSearch}
              placeHolder={searchPlaceholderText}
            />
          </Box>
        )}

        {children}
      </Container>
      <SideMenu
        userName={currentUser?.user?.name}
        userEmail={currentUser?.user?.email}
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </HeaderWrapper>
  );
};

export default Header;
