import { ReactNode, useState, useEffect } from 'react';
import SuspenseLoader from '../suspense-loader';
import { Box } from '@mui/material';
import { ButtonComp } from '@/components';
import Header from '../header';
import { navHeights } from '@/constants';

export interface IPageProps {
  isLoading?: boolean;
  children: ReactNode | ReactNode[];
  withNav?: boolean;
  withMenuToggler?: boolean;
  withSearch?: boolean;
  handleSearch?: (searchVal: string) => void;
  navTitle?: string;
  withButton?: boolean;
  handleButtonClick?: () => void;
  isButtonDisabled?: boolean;
  buttonTitle?: string;
  buttonLoadingText?: string;
  buttonLoading?: boolean;
  topButtonTitle?: string;
  withTopButton?: boolean;
  handleTopButtonClick?: () => void;
  handleArrowClick?: () => void;
  searchPlaceholderText?: string;
  positionFocusClick?: boolean;  // focus function from Login component
}

const Page = ({
  isLoading = false,
  children,
  withMenuToggler = false,
  withSearch = false,
  navTitle = '',
  withNav = true,
  withButton = false,
  handleButtonClick = () => {},
  buttonLoadingText = '',
  buttonTitle,
  buttonLoading = false,
  handleSearch = () => {},
  topButtonTitle = undefined,
  withTopButton = false,
  isButtonDisabled = false,
  handleTopButtonClick = () => {},
  handleArrowClick,
  searchPlaceholderText, 
  positionFocusClick,  // Default is false in Login component
}: IPageProps) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box
      sx={{
        margin: '0 auto',
        height: positionFocusClick ? 'auto' : `${windowHeight}px`, // Conditional height
        minHeight: positionFocusClick ? '100vh' : 'auto', // Conditional minHeight
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {isLoading ? (
        <SuspenseLoader />
      ) : (
        <>
          {withNav && (
            <Header
              searchPlaceholderText={searchPlaceholderText}
              handleArrowClick={handleArrowClick}
              title={navTitle}
              withMenuToggler={withMenuToggler}
              withSearch={withSearch}
              handleSearch={handleSearch}
            />
          )}
          <Box
            sx={{
              flex: '1',
              display: 'flex',
              flexDirection: 'column',
              height: withNav
                ? `${
                    windowHeight -
                    (withSearch ? navHeights.withSearch : navHeights.ordinary)
                  }px `
                : '100%',
              '&>div:first-of-type': {
                flex: '1',
                padding: '0 20px',
                overflow: 'auto',
                overflowX: 'hidden',
              },
            }}
          >
            {children}
            {withButton && (
              <Box
                sx={{
                  padding: '20px',
                  boxShadow: '0 -5px 5px -5px rgba(0,0,0,0.5);',
                }}
              >
                {withTopButton && (
                  <ButtonComp
                    variant='outlined'
                    sx={{
                      mb: '15px',
                      color: 'secondary.main',
                      borderColor: 'secondary.main',
                      '&:hover': {
                        color: 'primary.main',
                      },
                    }}
                    onClick={handleTopButtonClick}
                  >
                    {topButtonTitle}
                  </ButtonComp>
                )}

                <ButtonComp
                  disabled={isButtonDisabled}
                  loading={buttonLoading}
                  loadingText={buttonLoadingText}
                  onClick={handleButtonClick}
                >
                  {buttonTitle}
                </ButtonComp>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Page;
