import { Box, SxProps, Theme } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';

interface IFlexWrapperProps {
  direction?: CSSProperties['flexDirection'];
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  children: ReactNode | ReactNode[];
  sx?: SxProps<Theme>;
  handleClick?: () => void;
}
const FlexWrapper = ({
  direction = 'row',
  justifyContent = 'initial',
  alignItems = 'initial',
  children,
  sx,
  handleClick = () => {},
}: IFlexWrapperProps) => {
  return (
    <Box
      onClick={handleClick}
      sx={{
        display: 'flex',
        flexDirection: direction,
        justifyContent,
        alignItems,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default FlexWrapper;
