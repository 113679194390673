import { Box, Typography, styled } from '@mui/material';
import { t } from 'i18next';
import { Circle } from 'rc-progress';
import CountUp from 'react-countup';

interface ProgressCircleProps {
  percentage: number;
  contentNumber: number;
  contentUnit?: string;
  last_humidity?: any;
  use_humidity?: any;
  handleClick: () => void;
}

const StyledProgressContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50% , -50%)',
}));

const ProgressCircle = ({
  percentage = 0,
  contentNumber = 0,
  contentUnit = t('mpa'),
  handleClick,
  last_humidity = 0,
  use_humidity = false
}: ProgressCircleProps) => {

  // Clamp the percentage value to be between 0 and 100
  const clampedPercentage = Math.min(Math.max(percentage, 0), 100);

  return (
    <Box
      data-testid='progress-circle-box'
      onClick={handleClick}
      sx={{
        width: '142px',
        height: '142px',
        position: 'relative',
        padding: '11px 0',
      }}
    >
      <Circle
        data-testid='the-circle-item'
        transition='all 0.3s ease-in-out'
        trailColor='#ffff'
        percent={clampedPercentage}
        strokeWidth={10}
        trailWidth={10}
        strokeColor={{
          '1.04%': '#C5E8D8',
          '45.83%': '#009999',
          '100%': '#006464',
        }}
      />
      <StyledProgressContent sx={{ textAlign: 'center' }}>
        <Typography variant='h4' fontWeight='bold' color='black.darker'>
          <CountUp
            separator=''
            delay={0.1}
            duration={1}
            decimals={0}
            decimal='.'
            end={Number(contentNumber)}
          />
        </Typography>
        <Typography variant='body1' fontWeight='bold' fontSize={15}>
          {contentUnit}
        </Typography>
        { (last_humidity != 0 && use_humidity) &&
          (<div style={{
          fontSize: '0.875em'}}>{last_humidity}%RH</div>)
        }
      </StyledProgressContent>
    </Box>
  );
};

export default ProgressCircle;
