import { Box, IconButton, Input, styled } from '@mui/material';
import { Close, Search } from '../icons';
import { useState } from 'react';

const StyledInput = styled(Input)(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: '8px',
  '& input': {
    padding: '7px 32px 7px 35px',
  },
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.text.primary,
  '&:after , &:before': {
    display: 'none',
  },
}));

const StyledSearchIcon = styled(IconButton)({
  position: 'absolute',
  top: '50%',
  left: 0,
  transform: 'translate(8px , -45%)',
});

export const StyledCloseIcon = styled(IconButton)({
  position: 'absolute',
  top: '50%',
  right: 0,
  transform: 'translate(0 , -50%)',
  transition: 'opacity 0.3s ease-in-out',
});

export interface ISearchInputProps {
  placeHolder?: string;
  handleSearch: (searchVal: string) => void;
}

const SearchInput = ({ placeHolder = '', handleSearch }: ISearchInputProps) => {
  const [searchVal, setSearchVal] = useState('');

  return (
    <Box sx={{ position: 'relative' }}>
      <StyledInput
        onChange={e => {
          setSearchVal(e.target.value);
          handleSearch(e.target.value);
        }}
        value={searchVal}
        fullWidth
        type='text'
        placeholder={placeHolder}
      />
      <StyledSearchIcon sx={{ padding: 0 }} color='primary' aria-label='search'>
        <Search />
      </StyledSearchIcon>
      <StyledCloseIcon
        onClick={() => {
          setSearchVal(undefined);
          handleSearch(undefined);
        }}
        sx={{ opacity: searchVal ? 1 : 0 }}
      >
        <Close />
      </StyledCloseIcon>
    </Box>
  );
};

export default SearchInput;
