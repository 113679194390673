import { Box, SxProps, Theme, Typography, styled } from '@mui/material';
import FlexWrapper from '../flex-wrapper';
import { ReactNode } from 'react';

interface TitleProps {
  title: string;
  icon: ReactNode;
  sx?: SxProps<Theme>;
}

export const StyledIconWrap = styled('span')({
  display: 'inline-block',
  marginRight: '5px',
});
export const StyledLine = styled(Box)(({ theme }) => ({
  width: '100%',
  borderTop: `0.4px solid ${theme.palette.grey['400']}`,
  flex: 1,
  marginLeft: '10px',
}));

const Title = ({ title, icon, sx = {} }: TitleProps) => {
  return (
    <FlexWrapper sx={{ ...sx }} alignItems='center'>
      <StyledIconWrap>{icon}</StyledIconWrap>
      <Typography fontSize={14} variant='h1' color='grey.400'>
        {title}
      </Typography>
      <StyledLine />
    </FlexWrapper>
  );
};

export default Title;
